import { Directive, input } from '@angular/core';
import { AuiOption } from '@ral/ui:aui/utils';
import { AuiSelectComponent } from '../components/aui-select.component';
import { AuiSelectTemplateContext } from './select-item-template.context';

@Directive({
    selector: 'ng-template[auiSelectTemplate]',
    standalone: true,
})
export class AuiSelectTemplateDirective<T extends AuiOption> {
    public readonly auiSelectTemplate = input.required<AuiSelectComponent<T>>();

    static ngTemplateContextGuard<T extends AuiOption>(
        dir: AuiSelectTemplateDirective<T>,
        ctx: unknown
    ): ctx is AuiSelectTemplateContext<T> {
        return true;
    }
}
