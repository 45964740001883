import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { isValid, parseISO } from 'date-fns';
import { hostBinding } from 'ngxtension/host-binding';
import { InputTextModule } from 'primeng/inputtext';

@Component({
    selector: 'aui-readonly',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, InputTextModule, ReactiveFormsModule, DecimalPipe],
    providers: [DecimalPipe, DatePipe],
    template: `
        @if (value(); as value) {
            @if (Array.isArray(value)) {
                @for (item of value; track $index; let last = $last) {
                    @if (splitArrayToNewLine()) {
                        <aui-readonly [value]="item" />
                        @if (!last) {
                            ,
                        }
                    } @else {
                        <div>
                            <aui-readonly [value]="item" />
                        </div>
                    }
                }
            } @else {
                <span data-testid="readonly__..value">{{ value }}</span>
            }
        }
        <ng-content></ng-content>
    `,
})
export class AuiReadonlyComponent {
    Array = Array;
    translations = injectAuiTranslationDictionary();
    decimalPipe = inject(DecimalPipe);
    datePipe = inject(DatePipe);
    splitArrayToNewLine = signal<boolean>(false);
    trueText = input<string>(this.translations.yes());
    falseText = input<string>(this.translations.no());
    value = input(null, {
        transform: (value: unknown): string | string[] => {
            if (value === null || value === undefined) {
                return '';
            }

            if (typeof value === 'number') {
                return `${this.decimalPipe.transform(value)}`;
            }

            if (typeof value === 'boolean') {
                return `${value ? this.trueText() : this.falseText()}`;
            }

            if (typeof value === 'string') {
                // Check if the string is boolean
                if (value.toLocaleLowerCase() === 'true') {
                    this.trueText();
                }
                if (value.toLocaleLowerCase() === 'false') {
                    this.falseText();
                }

                // Check if the string is an ISO date
                if (value.match(/\d{4}-[01]\d-[0-3]\d/) && isValid(parseISO(value))) {
                    return this.datePipe.transform(value, 'medium') ?? '';
                }

                return value;
            }

            if (Array.isArray(value)) {
                return value;
            }

            if (value instanceof Date) {
                return this.datePipe.transform(value, 'medium') ?? '';
            }

            if (typeof value === 'object' && value !== null) {
                return Object.values(value);
            }

            return 'Unknown type';
        },
    });

    calculatedHostClasses = hostBinding('attr.class', signal('block aui-font-medium-16'));
}
