import { EnvironmentService } from '@abp/ng.core';
import { inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { exhaustMap, map, Observable, Subject, withLatestFrom } from 'rxjs';
import { O } from 'ts-toolbelt';
import { AccountDataSource } from '../infrastructure/account-datasource';
import { ResetPasswordInput, SendPasswordResetCode, VerifyPasswordResetTokenInput } from '../infrastructure/dto';
import { AuthStore } from '../services/auth.state';

@Injectable({ providedIn: 'root' })
export class ResetPasswordFacade extends ComponentStore<never> {
    private readonly accountDataSource = inject(AccountDataSource);
    private readonly environment = inject(EnvironmentService);
    private readonly authState = inject(AuthStore);

    private readonly _passwordResetLinkSent$ = new Subject<void>();
    public readonly passwordResetLinkSent$ = this._passwordResetLinkSent$.asObservable();

    private readonly _passwordResetSuccessFully$ = new Subject<void>();
    public readonly passwordResetSuccessFully$ = this._passwordResetSuccessFully$.asObservable();
    public readonly passwordResetFailed$ = this._passwordResetSuccessFully$.asObservable();
    private readonly _passwordResetFailed$ = new Subject<void>();
    private readonly _passwordResetTokenVerificationFailed$ = new Subject<void>();
    public readonly passwordResetTokenVerificationFailed$ = this._passwordResetTokenVerificationFailed$.asObservable();

    private readonly handleSendPasswordResetCode$ = this.effect(
        ($: Observable<O.Optional<SendPasswordResetCode, 'appName' | 'returnUrl' | 'returnUrlHash'>>) =>
            $.pipe(
                withLatestFrom(this.authState.returnUrl$),
                map(([input, returnUrl]) => ({ input, returnUrl })),
                exhaustMap(({ input, returnUrl }) =>
                    this.accountDataSource
                        .sendPasswordResetCode({
                            email: input.email,
                            returnUrl: input.returnUrl || returnUrl,
                            returnUrlHash: input.returnUrlHash || null,
                            appName: this.environment.getEnvironment().application.name,
                        })
                        .pipe(
                            tapResponse({
                                next: () => {
                                    this._passwordResetLinkSent$.next();
                                },
                                error: () => {},
                            })
                        )
                )
            )
    );
    private readonly handleResetPasswordReset$ = this.effect(($: Observable<ResetPasswordInput>) =>
        $.pipe(
            exhaustMap((input) =>
                this.accountDataSource.resetPassword(input).pipe(
                    tapResponse({
                        next: () => {
                            this._passwordResetSuccessFully$.next();
                        },
                        error: (err) => {
                            this._passwordResetFailed$.next();
                        },
                    })
                )
            )
        )
    );

    private readonly handleVerifyPasswordResetToken$ = this.effect(($: Observable<VerifyPasswordResetTokenInput>) =>
        $.pipe(
            exhaustMap((input) =>
                this.accountDataSource.validatePasswordResetToken(input).pipe(
                    tapResponse({
                        next: (isValid) => {
                            if (!isValid) this._passwordResetTokenVerificationFailed$.next();
                        },
                        error: () => {
                            this._passwordResetTokenVerificationFailed$.next();
                        },
                    })
                )
            )
        )
    );

    public sendPasswordResetCode(input: O.Optional<SendPasswordResetCode, 'appName' | 'returnUrl' | 'returnUrlHash'>) {
        this.handleSendPasswordResetCode$(input);
    }

    public resetPassword(input: ResetPasswordInput) {
        this.handleResetPasswordReset$(input);
    }

    public verifyPasswordResetToken(input: VerifyPasswordResetTokenInput) {
        this.handleVerifyPasswordResetToken$(input);
    }
}
