import { Directive, input } from '@angular/core';
import { AuiOption } from '@ral/ui:aui/utils';
import { AuiListBoxComponent } from '../components/aui-list-box.component';
import { AuiListBoxTemplateContext } from './list-box-template.context';

@Directive({
    selector: 'ng-template[auiListBoxTemplate]',
    standalone: true,
})
export class AuiListBoxTemplateDirective<T extends AuiOption> {
    public readonly auiListBoxTemplate = input.required<AuiListBoxComponent<T>>();

    static ngTemplateContextGuard<T extends AuiOption>(
        dir: AuiListBoxTemplateDirective<T>,
        ctx: unknown
    ): ctx is AuiListBoxTemplateContext<T> {
        return true;
    }
}
