import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiButtonStyleType } from '@ral/ui:aui/button';
import { AuiIcon, AuiIconComponent } from '@ral/ui:aui/icons';
import { AuiColor } from '@ral/ui:aui/utils';
import { hostBinding } from 'ngxtension/host-binding';
import { OverlayModule } from 'primeng/overlay';

@Component({
    selector: 'aui-badge',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [ReactiveFormsModule, AuiIconComponent, CommonModule, OverlayModule, AuiIconComponent],
    template: `
        <div class="flex items-center h-5 flex-nowrap gap-2">
            @if (icon(); as icon) {
                <div class="flex-none">
                    <aui-icon [icon]="icon"></aui-icon>
                </div>
            }
            @if (label(); as label) {
                <div class="flex-shrink min-w-0 ">
                    <span>
                        {{ label }}
                    </span>
                </div>
            }
            @if (rightIcon(); as rightIcon) {
                <div class="flex-none">
                    <aui-icon [icon]="rightIcon"></aui-icon>
                </div>
            }
        </div>
    `,
})
export class AuiBadgeComponent {
    color = input<AuiColor>('gray');
    label = input<string | null | undefined>(null);
    buttonStyle = input<AuiButtonStyleType>('solid');
    icon = input<AuiIcon | null>(null);
    rightIcon = input<AuiIcon | null>(null);

    rounded = input<boolean>(false);

    classes = hostBinding(
        'attr.class',
        computed(() => {
            const color = this.color();
            const buttonStyle = this.buttonStyle();
            const tailwindClasses = [
                'aui-font-regular-12',
                'border-solid',
                'border',
                'text-center',
                'transition-all',
                'focus:outline-none focus:ring',
                'rounded-full',
                'inline-block',
            ];

            const borders: Record<AuiColor, string[]> = {
                transparent: ['border-transparent', 'hover:border-transparent', 'active:border-transparent'],
                gray: ['border-gray', 'hover:border-gray-dark', 'active:border-gray-darken'],
                primary: ['border-primary', 'hover:border-primary-dark', 'active:border-primary-darken'],
                success: ['border-success', 'hover:border-success-dark', 'active:border-success-darken'],
                danger: ['border-danger', 'hover:border-danger-dark', 'active:border-danger-darken'],
                warning: ['border-warning', 'hover:border-warning-dark', 'active:border-warning-darken'],
                info: ['border-info', 'hover:border-info-dark', 'active:border-info-darken'],
                help: ['border-help', 'hover:border-help-dark', 'active:border-help-darken'],
            };

            const solidBackgrounds: Record<AuiColor, string[]> = {
                transparent: ['bg-transparent', 'hover:bg-transparent', 'active:bg-transparent'],
                gray: ['bg-gray', 'hover:bg-gray-dark', 'active:bg-gray-darken'],
                primary: ['bg-primary', 'hover:bg-primary-dark', 'active:bg-primary-darken'],
                success: ['bg-success', 'hover:bg-success-dark', 'active:bg-success-darken'],
                danger: ['bg-danger', 'hover:bg-danger-dark', 'active:bg-danger-darken'],
                warning: ['bg-warning', 'hover:bg-warning-dark', 'active:bg-warning-darken'],
                info: ['bg-info', 'hover:bg-info-dark', 'active:bg-info-darken'],
                help: ['bg-help', 'hover:bg-help-dark', 'active:bg-help-darken'],
            };

            const transparentBackgrounds: Record<AuiColor, string[]> = {
                transparent: [],
                gray: ['bg-gray/[0]', 'hover:bg-gray-dark/[.1]', 'active:bg-gray-darken/[.2]'],
                primary: ['bg-primary/[0]', 'hover:bg-primary-dark/[.1]', 'active:bg-primary-darken/[.2]'],
                success: ['bg-success/[0]', 'hover:bg-success-dark/[.1]', 'active:bg-success-darken/[.2]'],
                danger: ['bg-danger/[0]', 'hover:bg-danger-dark/[.1]', 'active:bg-danger-darken/[.2]'],
                warning: ['bg-warning/[0]', 'hover:bg-warning-dark/[.1]', 'active:bg-warning-darken/[.2]'],
                info: ['bg-info/[0]', 'hover:bg-info-dark/[.1]', 'active:bg-info-darken/[.2]'],
                help: ['bg-help/[0]', 'hover:bg-help-dark/[.1]', 'active:bg-help-darken/[.2]'],
            };

            const reverseText: Record<AuiColor, string[]> = {
                transparent: [],
                gray: ['text-gray-text'],
                primary: ['text-primary-text'],
                success: ['text-success-text'],
                danger: ['text-danger-text'],
                warning: ['text-warning-text'],
                info: ['text-info-text'],
                help: ['text-help-text'],
            };

            const coloredText: Record<AuiColor, string[]> = {
                transparent: ['text-inherit', 'hover:text-inherit', 'active:text-inherit'],
                gray: ['text-gray', 'hover:text-gray', 'active:text-gray'],
                primary: ['text-primary', 'hover:text-primary-dark', 'active:text-primary-darken'],
                success: ['text-success', 'hover:text-success-dark', 'active:text-success-darken'],
                danger: ['text-danger', 'hover:text-danger-dark', 'active:text-danger-darken'],
                warning: ['text-warning', 'hover:text-warning-dark', 'active:text-warning-darken'],
                info: ['text-info', 'hover:text-info-dark', 'active:text-info-darken'],
                help: ['text-help', 'hover:text-help-dark', 'active:text-help-darken'],
            };

            const focusRing: Record<AuiColor, string[]> = {
                transparent: [],
                gray: ['ring-gray'],
                primary: ['ring-primary'],
                success: ['ring-success'],
                danger: ['ring-danger'],
                warning: ['ring-warning'],
                info: ['ring-info'],
                help: ['ring-help'],
            };

            //       </div>
            // @if (this.icon(); as icon) {
            //   <div class="p-1 text-center">
            //     <aui-icon [icon]="icon"></aui-icon>
            //   </div>
            // } @else {
            //   <div class="px-2 text-center py-1 max-w-max">
            //     <span>
            //       {{ this.label() }}
            //     </span>
            //   </div>

            const label = this.label();
            if (!label) {
                tailwindClasses.push('px-1 text-center');
            } else {
                tailwindClasses.push('px-2');
            }

            if (buttonStyle === 'solid') {
                tailwindClasses.push(...borders[color]);
                tailwindClasses.push(...solidBackgrounds[color]);
                tailwindClasses.push(...reverseText[color]);
            }
            if (buttonStyle === 'outline') {
                tailwindClasses.push(...borders[color]);
                tailwindClasses.push(...solidBackgrounds['transparent']);
                tailwindClasses.push(...coloredText[color]);
            }
            if (buttonStyle === 'decent') {
                const decentBackground = [...transparentBackgrounds[color]];
                decentBackground[0] = 'bg-gray-dark/[.05]';
                tailwindClasses.push(...borders['transparent']);
                tailwindClasses.push(...decentBackground);
                tailwindClasses.push(...coloredText['transparent']);
            }
            if (buttonStyle === 'text') {
                tailwindClasses.push(...borders['transparent']);
                tailwindClasses.push(...transparentBackgrounds[color]);
                tailwindClasses.push(...coloredText[color]);
            }

            // ring
            tailwindClasses.push(...focusRing[color]);

            return tailwindClasses.join(' ');
        })
    );
}
