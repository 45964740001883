import { catchError, Observable, OperatorFunction } from 'rxjs';
import { handleNSwagException, handleOpenApiException } from './handle-nswag-exception';

export function catchNSwagAbpException<T>(): OperatorFunction<T, T> {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            catchError((exception) => {
                console.error(exception);
                handleNSwagException(exception);
            })
        );
}

export function catchOpenApiAbpException<T>(logError = true): OperatorFunction<T, T> {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            catchError((exception) => {
                if (logError) {
                    console.error(JSON.stringify(exception));
                }
                handleOpenApiException(exception);
            })
        );
}
