import { patchState, signalState } from '@ngrx/signals';

import { effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { AuiNavigationSection } from '../model';

export const createAuiNavigationFactory = () => {
    // const activatedRoute = inject(ActivatedRoute);
    const state = signalState({
        // TODO: Select model
        sections: [] as AuiNavigationSection[],
    });

    const router = inject(Router);

    const routeChange = toSignal(
        router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => router.url),
            startWith(router.url)
        )
    );

    const setSections = (sections: AuiNavigationSection[]) => {
        patchState(state, { sections });
    };

    effect(() => {
        const route = routeChange();
    });

    return {
        sections: state.sections,
        setSections,
    };
};

export type AuiNavType = ReturnType<typeof createAuiNavigationFactory>;
