import { LocalizationModule, LocalizationWithDefault } from '@abp/ng.core';
import { JsonPipe } from '@angular/common';
import { Component, computed, contentChild, effect, inject, input, signal, untracked } from '@angular/core';
import { NgControl, ValidationErrors, Validators } from '@angular/forms';
import {
    injectValidationDictionary,
    provideValidationDictionary,
    ValidationMessages,
} from '@ral/utils:core/validation';
import { ApplyPipe, CallPipe } from 'ngxtension/call-apply';
import { createInjectionToken, createNoopInjectionToken } from 'ngxtension/create-injection-token';
import { hostBinding } from 'ngxtension/host-binding';
import { AUI_FIELD_ROW_STYLES } from '../model/row-field-styles';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { map, of, startWith, switchMap } from 'rxjs';

export type ValidationMessage = { [key: string]: string | LocalizationWithDefault };

export const [injectVM, provideValidationMessage] = createNoopInjectionToken<ValidationMessage, false>(
    'Validation Messages'
);

export const [injectValidationMessage, provideValidationMessages] = createInjectionToken(() => {
    let validationMessages = injectVM({ optional: true }) || {};

    const a = injectValidationMessage({ skipSelf: true, optional: true }) || {
        validationMessages: {},
    };

    validationMessages = {
        ...a.validationMessages,
        ...validationMessages,
    };

    return {
        validationMessages,
    };
});

@Component({
    standalone: true,
    selector: 'aui-form-field-error',
    template: `
        @if (parent.ngControl()?.control?.dirty) {
            @for (error of errors() | call: keys; track $index) {
                <span data-testid="aui-form-field-error" [class]="errorClasses()">
                    {{ translate | apply: error : errors() : validationMessages }}
                </span>
            }
        }
    `,
    imports: [JsonPipe, LocalizationModule, CallPipe, ApplyPipe],
    //Tady se ten provide asi odstraní?
    providers: [provideValidationDictionary()],
})
export class AuiFormFieldErrorComponent {
    statusChanged = signal(undefined);
    status = signal(undefined);
    rowStyle = signal<string>('');
    errorClasses = computed(() => {
        const rowStyle = this.rowStyle();
        return `text-danger aui-font-regular-12 block pt-1 first:pt-2 ${rowStyle}`;
    });

    public readonly parent = inject(AuiFormFieldComponent);

    public readonly validationMessages = injectValidationDictionary();

    controlToObservable$ = toObservable(this.parent.ngControl).pipe(
        map((ctrl) => ctrl?.control),
        switchMap((control) =>
            control
                ? control.statusChanges.pipe(
                      startWith(null),
                      map(() => control.errors)
                  )
                : of(null)
        )
    );

    errors = toSignal(this.controlToObservable$, { initialValue: null });

    translate(error: string, errors: ValidationErrors | null, validationMessages: Partial<ValidationMessages>) {
        const fn = validationMessages[error];
        if (fn === null || fn === undefined) return '';
        return fn(<never>(errors ?? {})[error]);
    }

    keys(errors: ValidationErrors | null) {
        if (errors) return Object.keys(errors);
        return [];
    }
}

@Component({
    standalone: true,
    selector: 'aui-form-field-label',
    template: `
        <label class="aui-font-regular-14 truncate">
            {{ label() }}
            @if (isRequiredField() && !hideRequiredMarker()) {
                <span>*</span>
            }
        </label>
    `,
})
export class AuiFormFieldLabelComponent {
    public readonly parent = inject(AuiFormFieldComponent);

    label = input.required<string | null>();
    rowStyle = signal<string>('');
    hideRequiredMarker = input<boolean>(false);

    controlToObservable$ = toObservable(this.parent.ngControl).pipe(
        map((ctrl) => ctrl?.control),
        switchMap((control) =>
            control
                ? control.statusChanges.pipe(
                      startWith(null),
                      map(() => control.hasValidator(Validators.required))
                  )
                : of(false)
        )
    );

    isRequiredField = toSignal(this.controlToObservable$, { initialValue: false });

    /*public readonly labelFor = computed(() => {
const control = this.parent.ngControl();
return control?.name;
});*/

    public readonly isRequired = computed(() => {
        const control = this.parent.ngControl();
        if (control && control.control) {
            return control.control.hasValidator(Validators.required);
        }
        return false;
    });

    calculatedHostClasses = hostBinding(
        'attr.class',
        computed(() => {
            const rowMinWidth = this.rowStyle();
            return `flex items-center flex-none ${rowMinWidth}`;
        })
    );
}

@Component({
    standalone: true,
    selector: 'aui-form-field-description',
    template: `@if (description()) {
        <p>{{ description() }}</p>
    }`,
})
export class AuiFormFieldDescriptionComponent {
    description = input.required<string | null>();
    rowStyle = signal<string>('');

    calculatedHostClasses = hostBinding(
        'attr.class',
        computed(() => {
            const rowMinWidth = this.rowStyle();
            return `flex items-center flex-none aui-font-regular-12 empty:hidden ${rowMinWidth}`;
        })
    );
}

export const [injectDefaultDefaultRowStyle, provideDefaultDefaultRowStyle] = createInjectionToken(
    (defaultValue: keyof typeof AUI_FIELD_ROW_STYLES = 'md180') => {
        return defaultValue;
    }
);

@Component({
    standalone: true,
    selector: 'aui-form-field',
    template: `
        <div class="flex flex-col items-stretch gap-1 {{ fieldRowStyle() }}">
            <div class="empty:hidden">
                <ng-content select="aui-form-field-label" />
                <ng-content select="aui-form-field-description" />
            </div>
            <div class="empty:hidden flex-grow w-full min-w-0">
                <ng-content />
            </div>
        </div>
        <aui-form-field-error />
    `,
    imports: [JsonPipe, AuiFormFieldErrorComponent],
})
export class AuiFormFieldComponent {
    ngControl = contentChild(NgControl);

    label = contentChild(AuiFormFieldLabelComponent);
    error = contentChild(AuiFormFieldErrorComponent);
    description = contentChild(AuiFormFieldDescriptionComponent);

    rowStyle = input<keyof typeof AUI_FIELD_ROW_STYLES>(injectDefaultDefaultRowStyle());

    fieldRowStyle = signal<string>('');

    errors = computed(() => {
        const control = this.ngControl();
        if (control && control.touched) {
            return control.errors;
        }
        return [];
    });

    calculatedHostClasses = hostBinding('attr.class', signal('@container block'));
    rowWithChanged = effect(() => {
        const rowStyle = this.rowStyle();
        const label = this.label();
        const description = this.description();
        const error = this.error();

        untracked(() => {
            label?.rowStyle.set(AUI_FIELD_ROW_STYLES[rowStyle].label);
            description?.rowStyle.set(AUI_FIELD_ROW_STYLES[rowStyle].description);
            error?.rowStyle.set(AUI_FIELD_ROW_STYLES[rowStyle].error);
            this.fieldRowStyle.set(AUI_FIELD_ROW_STYLES[rowStyle].field);
        });
    });
}

/* Deprecated - has to be removed in hr-portal and licence server*/
@Component({
    standalone: true,
    selector: 'aui-form-field-control',
    template: ` <ng-content /> `,
    styles: [':host { @apply  col-span-2 }'],
})
export class AuiFormFieldControlComponent {}
