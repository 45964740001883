import { createInjectionToken, createNoopInjectionToken } from 'ngxtension/create-injection-token';
import { createAuiNavigationFactory } from './aui-main-navigation.service';
import { AuiNavigation, AuiNavigationItem, AuiNavigationSection } from '../model';

export const [injectAuiMainNavigation, provideAuiMainNavigation] = createInjectionToken(createAuiNavigationFactory, {
    isRoot: true,
});

export function createNavigationProviders(description: string) {
    const [injectAuiMainNavigationSections, provideAuiMainNavigationSections] = createNoopInjectionToken<
        AuiNavigationSection[],
        true
    >(description + ' Aui navigation', { multi: true });

    const [injectAuiMainNavigationItems, provideAuiMainNavigationItems] = createNoopInjectionToken<
        AuiNavigationItem[],
        true
    >(description + 'Aui navigation', { multi: true });

    const [injectNavigations] = createInjectionToken<() => AuiNavigation>(() => {
        const sections = (injectAuiMainNavigationSections({ optional: true }) ?? []).reduce(
            (acc, val) => acc.concat(val),
            [{ id: null }]
        );

        const items = (injectAuiMainNavigationItems({ optional: true }) ?? [])
            .reduce((acc, val) => acc.concat(val), [])
            .map((e) => ({
                ...e,
                sectionId: e.sectionId === undefined ? null : sections.find((x) => x.id === e.sectionId)?.id ?? null,
            }));

        return {
            sections,
            items,
        };
    });

    return [provideAuiMainNavigationSections, provideAuiMainNavigationItems, injectNavigations] as const;
}
