import { AptLanguageInfo, ILanguagePreferencesService } from '@ral/utils:core/localization';
import { ConfigStateService, SessionStateService } from '@abp/ng.core';
import { computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

export function abpLanguagePreferencesService(): ILanguagePreferencesService {
    const configState = inject(ConfigStateService);
    const sessionState = inject(SessionStateService);

    const languages$ = configState
        .getDeep$('localization.languages')
        .pipe(map((languages) => AptLanguageInfo.array().parse(languages)));
    const languages = toSignal(languages$, { initialValue: [] });

    const currentSessionLanguage = toSignal(sessionState.getLanguage$(), { initialValue: '' });

    const currentLanguage = computed(() => languages().find((x) => x.cultureName === currentSessionLanguage()) ?? null);

    const changeLanguage = (cultureName: string) => {
        const _languages = languages();
        const _currentLanguage = currentLanguage();

        if (cultureName === _currentLanguage?.cultureName) {
            return;
        }
        if (!_languages.find((x) => x.cultureName === cultureName)) {
            return;
        }

        sessionState.setLanguage(cultureName);
        location.reload();
    };

    return {
        languages,
        currentLanguage,
        changeLanguage,
    };
}
