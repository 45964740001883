import { computed, signal, Signal } from '@angular/core';
import { AptLanguageInfo } from './apt-language-info';
import { createInjectionToken } from 'ngxtension/create-injection-token';

export interface ILanguagePreferencesService {
    languages: Signal<AptLanguageInfo[]>;
    currentLanguage: Signal<AptLanguageInfo | null>;
    changeLanguage: (cultureName: string) => void;
}

export const [injectLanguagePreferences, provideLanguagePreferences] = createInjectionToken<
    () => ILanguagePreferencesService
>(
    () => {
        const languages = signal<AptLanguageInfo[]>([
            {
                cultureName: 'en',
                displayName: 'English',
                uiCultureName: 'en',
                twoLetterISOLanguageName: 'en',
                flagIcon: null,
            },
        ]);

        return {
            languages,
            currentLanguage: computed(() => languages()[0]),
            changeLanguage: () => {},
        };
    },
    { multi: false, isRoot: true }
);
