import { inject, Injectable } from '@angular/core';
import { exhaustMap, map, Observable, tap, withLatestFrom } from 'rxjs';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { AuthStore } from '../services/auth.state';
import { ExternalLoginService } from '../infrastructure/external-login.service';
import { ExternalProvider } from '../entities/external-provider';
import { ConfigConst } from '../const/config';
import { ConfigStateService } from '@abp/ng.core';

export interface ExternalLoginState {
    providers: Array<ExternalProvider>;
}

@Injectable({ providedIn: 'root' })
export class ExternalLoginFacade extends ComponentStore<ExternalLoginState> {
    public readonly providers$ = this.select((s) => s.providers);
    public readonly isExternalProviderAvailable$ = this.select((s) => s.providers.length !== 0);
    private readonly authState = inject(AuthStore);
    private readonly configState = inject(ConfigStateService);
    public readonly isLocalLoginEnabled$ = this.configState
        .getSetting$(ConfigConst.isLocalLoginEnabled)
        .pipe(map((value) => value?.toLowerCase() !== 'false'));
    private readonly externalLoginService = inject(ExternalLoginService);
    private readonly getExternalProviders$ = this.effect(($: Observable<void>) =>
        $.pipe(
            exhaustMap(() =>
                this.externalLoginService.getExternalProviders().pipe(
                    tapResponse({
                        next: (providers) => {
                            this.patchState({ providers });
                        },
                        error: () => {
                            // Do nothing
                        },
                    })
                )
            )
        )
    );

    private readonly handleLoginWithExternalProvider$ = this.effect(($: Observable<ExternalProvider>) =>
        $.pipe(
            withLatestFrom(this.authState.returnUrl$),
            map(([provider, returnUrl]) => ({ provider, returnUrl })),
            tap(({ provider, returnUrl }) => this.externalLoginService.externalLogin(provider, returnUrl))
        )
    );

    constructor() {
        super({
            providers: [],
        });

        this.getExternalProviders$();
    }

    public loginWithExternalProvider(provider: ExternalProvider) {
        this.handleLoginWithExternalProvider$(provider);
    }
}
