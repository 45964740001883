import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';

import { DialogModule } from '@angular/cdk/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAuiToastService } from '@ral/ui:aui/toast';

import { provideAuiConfirmation } from '@ral/ui:aui/confirmation';

import { DialogService } from 'primeng/dynamicdialog';
import { injectAuiThemeStore } from '@ral/ui:aui/theme';
import { injectAuthService } from '@ral/utils:core/auth';
import { AuiNavigationItem } from '@ral/ui:aui/navigation';
import { provideAccountNavigationItems } from './services/aui-theme-account-navigation';

export const provideAuiBasicTheme = (): (Provider | EnvironmentProviders)[] => [
    provideAuiToastService,
    // routesProvider(),
    DialogService,
    provideAuiConfirmation,
    importProvidersFrom([DialogModule]),
    provideAnimations(),
    provideAccountNavigationItems((auiTheme = injectAuiThemeStore(), auth = injectAuthService()) => [
        ...auiTheme.availableThemes().map(
            (theme): AuiNavigationItem => ({
                label: theme,
                icon: 'palette',
                clickEvent: () => auiTheme.selectTheme(theme),
                visible: auiTheme.isNotCurrentTheme(theme),
            })
        ),
        {
            label: 'Odhlásit',
            icon: 'power-off',
            clickEvent: () => auth.logout(),
        },
    ]),
];
