import { provideAuthorizationService, provideAuthService } from '@ral/utils:core/auth';
import { computed, inject } from '@angular/core';
import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import { toSignal } from '@angular/core/rxjs-interop';

export const abpAuthService = provideAuthService(() => {
    const configState = inject(ConfigStateService);
    const auth = inject(AuthService);

    const currentUser = toSignal(configState.getOne$('currentUser'), { initialValue: null });
    const isAuthenticated = computed(() => {
        const user = currentUser();
        if (user) return user.isAuthenticated;
        return false;
    });

    return {
        currentUser,
        isAuthenticated,
        logout: () => auth.logout(),
    };
});

export const abpAuthorizationService = provideAuthorizationService(() => {
    const permissionService = inject(PermissionService);

    return {
        getGrantedPolicy: (key: string) => toSignal(permissionService.getGrantedPolicy$(key), { initialValue: false }),
        getGrantedPolicy$: (key: string) => permissionService.getGrantedPolicy$(key),
        getGrantedPolicySnapshot: (key: string) => permissionService.getGrantedPolicy(key),
    };
});
