import { createInjectionToken } from 'ngxtension/create-injection-token';
import { BehaviorSubject, Observable } from 'rxjs';
import { signal, Signal } from '@angular/core';

export const [injectAuthorizationService, provideAuthorizationService] = createInjectionToken(() => {
    return {
        getGrantedPolicy: (key: string): Signal<boolean> => signal(true),
        getGrantedPolicy$: (key: string): Observable<boolean> => new BehaviorSubject<boolean>(true),
        getGrantedPolicySnapshot: (key: string): boolean => true,
    };
});
