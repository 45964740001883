import {
    ConfigStateService,
    EnvironmentService,
    LazyLoadService,
    LOADING_STRATEGY,
    LocalizationModule,
    SessionStateService,
} from '@abp/ng.core';
import { HttpClient } from '@angular/common/http';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiSelectComponent } from '@ral/ui:aui/select';
import { injectAuiThemeStore } from '@ral/ui:aui:theme:basic';
import { TenantBoxComponent } from '@ralba-ng/core/account/feature-switch-tenant';
import { injectLanguagePreferences } from '@ral/utils:core/localization';

@Component({
    standalone: true,
    selector: 'ralba-ng-auth-layout',
    imports: [
        RouterOutlet,
        FormsModule,
        LocalizationModule,
        TenantBoxComponent,
        AuiSelectComponent,
        AuiButtonComponent,
    ],
    template: `
        <main class="min-w-full min-h-screen flex flex-row items-center justify-center">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 pt-20">
                @if (languagesOptions(); as languages) {
                    <div class="absolute top-4 right-4">
                        @if (languages.length > 1) {
                            <aui-select
                                [options]="languages"
                                [value]="currentLanguage()"
                                (valueChange)="handleChangeLang($event)"
                                [allowNull]="false"
                            ></aui-select>
                        } @else {
                            @if (languagesOptions()[0]; as availableLanguageOption) {
                                <aui-button
                                    [label]="availableLanguageOption.label"
                                    (click)="handleChangeLang(availableLanguageOption.value)"
                                    [buttonStyle]="'text'"
                                ></aui-button>
                            }
                        }
                    </div>
                }
                <!--(click)="darkMode.toggleMode()"-->
                <a class="flex items-center mb-6 gap-8 text-2xl font-semibold text-gray-900 w-full justify-center">
                    <!-- {{ 'RalbaAccount::' + appName | abpLocalization }} -->
                    <img [src]="logo()" class="max-h-[80px]" />
                    <span class="aui-font-bold-24 lg:aui-font-bold-36 whitespace-nowrap"> {{ appName() }} </span>
                </a>

                <div>
                    <core-account-tenant-box />
                </div>

                <div
                    class="w-full bg-surface-background-light rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 md:min-w-[500px]"
                >
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <div class="flex flex-col items-center p-4 gap-2">
                    @for (theme of auiThemeState.availableThemesWithoutCurrent(); track $index) {
                        <span (click)="setTheme(theme)" class="aui-font-regular-12">
                            {{ 'AppthorAccount::SwitchTheme' | abpLocalization: theme }}
                        </span>
                    }
                </div>
            </div>
        </main>
    `,
})
export class AuthLayoutComponent {
    environment = inject(EnvironmentService);
    auiThemeState = injectAuiThemeStore();
    sessionState = inject(SessionStateService);
    protected readonly Float32Array = Float32Array;
    private readonly languagePreferences = injectLanguagePreferences();
    public languagesOptions = computed(() =>
        this.languagePreferences.languages().map((lang) => ({
            label: lang.displayName,
            value: lang.cultureName,
        }))
    );
    public currentLanguage = computed(() => this.languagePreferences.currentLanguage()?.cultureName ?? '');
    private readonly http = inject(HttpClient);
    private configState = inject(ConfigStateService);
    public readonly appName = toSignal(this.configState.getSetting$('Appthor.Account.SignInApplicationName'), {
        initialValue: '',
    });
    public readonly logo = toSignal(this.configState.getSetting$('Appthor.Account.SignInApplicationLogo'), {
        initialValue: '',
    });

    constructor(private lazyLoad: LazyLoadService) {
        this.lazyLoad.load(LOADING_STRATEGY.AppendAnonymousStyleToHead('/custom-auth.css')).subscribe();
    }

    handleChangeLang(cultureName: string) {
        this.languagePreferences.changeLanguage(cultureName);
    }

    switchTenant(name: string | null) {
        this.http.post('/auth/switch-tenant', { name }).subscribe(() => {
            location.reload();
        });
    }

    setTheme(theme: string) {
        this.auiThemeState.selectTheme(theme);
    }
}

@Component({
    standalone: true,
    selector: 'ralba-ng-auth-layout2',
    imports: [RouterOutlet, FormsModule, LocalizationModule],
    template: `
        <main class="min-w-full min-h-screen bg-primary flex flex-row items-stretch justify-end">
            <div class="bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </main>
    `,
})
export class AuthLayout2ComponentComponent {
    constructor(private lazyLoad: LazyLoadService) {
        this.lazyLoad.load(LOADING_STRATEGY.AppendAnonymousStyleToHead('/custom-auth.css')).subscribe();
    }
}
