import { LocalizationModule } from '@abp/ng.core';
import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'core-account-reset-password-success',
    imports: [LocalizationModule],
    template: `
        <div>
            <span class="aui-font-regular-16 pb-4">{{
                'AppthorAccount::YourPasswordIsSuccessfullyReset' | abpLocalization
            }}</span>
        </div>
    `,
})
export class CoreAccountResetPasswordSuccessComponent {}
