import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    contentChild,
    ContentChild,
    inject,
    input,
    OnInit,
    signal,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuiBadgeComponent } from '@ral/ui:aui/badge';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiIcon } from '@ral/ui:aui/icons';
import { AuiListBoxComponent, AuiListBoxTemplateDirective } from '@ral/ui:aui/listbox';
import { AuiReadonlyComponent } from '@ral/ui:aui/readonly';
import { AuiTooltipDirective } from '@ral/ui:aui/tooltip';
import { AuiColor, AuiOption } from '@ral/ui:aui/utils';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';
import { hostBinding } from 'ngxtension/host-binding';
import { injectResize } from 'ngxtension/resize';
import { rxEffect } from 'ngxtension/rx-effect';
import { AuiSelectTemplateDirective } from '../directives/select-item-template.directive';

@Component({
    selector: 'aui-select',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        OverlayModule,
        AuiListBoxComponent,
        AuiBadgeComponent,
        AuiButtonComponent,
        AuiTooltipDirective,
        AuiReadonlyComponent,
        AuiListBoxTemplateDirective,
    ],
    hostDirectives: [{ directive: NgxControlValueAccessor, inputs: ['value'], outputs: ['valueChange'] }],
    template: `
        @if (!readonly()) {
            <div [class]="classes()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                <!-- 26px -->
                <div
                    data-testid="select-origin"
                    class="flex-1 flex-shrink overflow-hidden self-center pr-1"
                    (click)="onClick()"
                >
                    @if (selectedOption()) {
                        @if (useBadge()) {
                            <div class="py-0.5">
                                <!-- 22px + 4px -->
                                <aui-badge
                                    [color]="selectedColor() ?? defaultBadgeColor()"
                                    [rounded]="true"
                                    [buttonStyle]="'outline'"
                                    [label]="selectedLabel()"
                                    [icon]="selectedIcon()"
                                >
                                </aui-badge>
                            </div>
                        } @else {
                            <!-- 20px -->
                            <span
                                class="aui-font-regular-16 leading-6 block text-nowrap py-px text-ellipsis overflow-hidden"
                            >
                                {{ selectedLabel() }}
                            </span>
                        }
                    } @else {
                        <!-- 20px -->
                        <span class="placeholder aui-font-regular-16 leading-6 block py-px">
                            {{ placeholder() }}
                        </span>
                    }
                </div>
                @if (showClearButton() && canUserInteract()) {
                    <div class="flex-none self-center" (click)="clearOption()">
                        <aui-button [icon]="'times'" [buttonStyle]="'text'" tooltip="Clear control"></aui-button>
                    </div>
                }
                @if (showSelectionArrow() && canUserInteract()) {
                    <div class="flex-none self-center" (click)="onClick()">
                        <aui-button [icon]="selectionArrowIcon()" [buttonStyle]="'text'"></aui-button>
                    </div>
                }

                <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="trigger"
                    [cdkConnectedOverlayOpen]="isOpen"
                    [cdkConnectedOverlayHasBackdrop]="true"
                    [cdkConnectedOverlayBackdropClass]="'backdrop'"
                    (backdropClick)="onBlur()"
                >
                    <div
                        class="backdrop mt-2  w-full shadow-xl min-w-48 rounded aui-font-regular-14"
                        [style.width.px]="dimension()?.width"
                    >
                        <aui-list-box
                            [readonly]="readonly()"
                            [formControl]="control"
                            [options]="options() || []"
                            [filtering]="filtering()"
                            [allowNull]="allowNull()"
                            [emptyLabel]="emptyLabel()"
                            #listBox
                        >
                            @if (itemTemplate(); as itemTemplate) {
                                <ng-template [auiListBoxTemplate]="listBox" let-item>
                                    <ng-container
                                        *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
                                    ></ng-container>
                                </ng-template>
                            }
                            @if (emptyTemp) {
                                <ng-template #emptyTemplate>
                                    <ng-container *ngTemplateOutlet="emptyTemp"></ng-container>
                                </ng-template>
                            }
                        </aui-list-box>
                    </div>
                </ng-template>
            </div>
        } @else {
            <aui-readonly [value]="selectedLabel()" class="h-full max-w-full py-[1px]" />
        }
    `,
})
export class AuiSelectComponent<T extends AuiOption> implements OnInit {
    isOpen = false;
    translations = injectAuiTranslationDictionary();

    options = input<T[] | null>(null);
    placeholder = input<string | null>(this.translations.selectPlaceholder());
    filtering = input<boolean>(false);
    readonly = input<boolean>(false);
    border = input<boolean>(true);
    useBadge = input<boolean>(false);
    allowNull = input<boolean>(true);
    showSelectionArrow = input<boolean>(true);
    selectionArrowIcon = input<AuiIcon>('chevron-down');
    showClearButton = input<boolean>(false);
    defaultBadgeColor = input<AuiColor>('gray');

    emptyLabel = input<string>(this.translations.noItems());
    itemTemplate = contentChild(AuiSelectTemplateDirective, { read: TemplateRef });
    @ContentChild('emptyTemplate', { read: TemplateRef }) emptyTemp?: TemplateRef<unknown>;
    calculatedHostClasses = hostBinding('attr.class', signal('w-full'));
    protected cva = inject<NgxControlValueAccessor<T['value'] | null>>(NgxControlValueAccessor);

    dimension = toSignal(injectResize());

    selectedOption = computed(() => {
        const selectedValue = this.cva.value$() ?? this.cva.value;
        const options = this.options();
        if (selectedValue && options) {
            return options.find((option) => option.value === selectedValue);
        }
        return null;
    });
    selectedLabel = computed(() => {
        return this.selectedOption()?.label ?? null;
    });
    selectedIcon = computed(() => {
        return this.selectedOption()?.icon ?? null;
    });
    selectedColor = computed(() => {
        return this.selectedOption()?.color;
    });
    canUserInteract = computed(() => {
        return !(this.readonly() || this.cva.disabled$());
    });
    classes = computed(() => {
        const disabled = this.cva.disabled;

        let tailwindClasses = [
            'flex',
            'flex-nowrap',
            'rounded',
            'aui-font-regular-14',
            'transition-all',
            'w-full',
            'py-0.5',
            'pl-2',
            'pr-1',
            'min-h-8',
            'gap-1',
        ];

        // disabled opacity + remove active, focus and hover states
        if (this.border()) {
            tailwindClasses.push('border border-surface-background-darker hover:border-surface-background-darken');
        }

        if (disabled) {
            tailwindClasses.push('bg-surface-background-dark', 'border-surface-background');
            tailwindClasses = tailwindClasses.filter(
                (p) => !(p.includes('hover') || p.includes('active') || p.includes('focus'))
            );
        }

        return tailwindClasses.join(' ');
    });

    clearOption() {
        if (this.canUserInteract()) {
            this.cva.value = null;
        }
    }

    onClick() {
        if (this.canUserInteract()) {
            this.isOpen = true;
        }
    }

    onBlur() {
        this.isOpen = false;
        this.cva.markAsTouched();
    }

    control = new FormControl<AuiOption['value']>('');
    controlEffect = rxEffect(this.control.valueChanges, (val) => {
        if (this.canUserInteract()) {
            if (!Array.isArray(val)) {
                this.cva.value = val;
            }
            this.isOpen = false;
        }
    });

    ngOnInit(): void {
        this.control.setValue(this.cva.value);
    }
}
