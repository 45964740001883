import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const canActivatePasswordReset: CanActivateFn = (route) => {
    const router = inject(Router);
    const userId = route.queryParams['UserId'];
    const resetToken = route.queryParams['resetToken'];
    if (userId && resetToken) return true;

    return router.createUrlTree(['/']);
};
