import { z } from 'zod';

export const AptLanguageInfo = z.object({
    cultureName: z.string(),
    uiCultureName: z.string(),
    displayName: z.string(),
    twoLetterISOLanguageName: z.string(),
    flagIcon: z.string().nullable(),
});

export type AptLanguageInfo = z.infer<typeof AptLanguageInfo>;
