import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiReadonlyComponent } from '@ral/ui:aui/readonly';
import { AuiColor } from '@ral/ui:aui/utils';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';
import { hostBinding } from 'ngxtension/host-binding';
import { InputTextModule } from 'primeng/inputtext';

@Component({
    selector: 'aui-input',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, InputTextModule, ReactiveFormsModule, AuiReadonlyComponent],
    hostDirectives: [
        {
            directive: NgxControlValueAccessor,
            inputs: ['value'],
            outputs: ['valueChange'],
        },
    ],
    styleUrl: 'aui-input.component.scss',
    template: `
        @if (!readonly()) {
            <input
                data-testid="input"
                [value]="value()"
                [type]="type()"
                (input)="input($event)"
                [disabled]="cva.disabled$()"
                (blur)="cva.markAsTouched()"
                [placeholder]="placeholder()"
                [autocomplete]="autoCompleteProp() ?? 'off'"
                class="h-full px-2 py-1 focus:outline-none focus:ring bg-transparent min-w-full max-w-full text-sm"
            />
        } @else {
            <aui-readonly [value]="value()" class="h-full max-w-full py-[1px]" data-testid="readonly__value" />
        }
    `,
    styles: [
        `
            :host {
                @apply block;
            }

            :host #file-upload-button {
                display: none;
            }
        `,
    ],
})
export class AuiInputComponent {
    translations = injectAuiTranslationDictionary();
    showClear = input<boolean>(false);
    placeholder = input<string | null>(this.translations.inputPlaceholder());
    readonly = input<boolean>(false);
    type = input<'text' | 'number' | 'email' | 'password' | 'file'>('text');
    color = input<AuiColor>('gray');
    autoCompleteProp = input<string | null>('off');
    rounded = input(false);

    protected cva = inject<NgxControlValueAccessor<string | null | undefined>>(NgxControlValueAccessor);
    value = computed(() => {
        return this.cva.value$() ?? null;
    });

    calculatedHostClasses = hostBinding(
        'attr.class',
        computed(() => {
            if (this.readonly()) {
                return 'block w-full min-h-8 py-1 leading-8';
            }

            const tailwindClasses = [
                'block',
                'h-8',
                'aui-font-regular-16',
                'transition-all',
                'overflow-hidden',
                'border',
                'border-input',
                'w-full',
            ];
            const color = this.color();
            const rounded = this.rounded();

            const focusRing: Record<AuiColor, string[]> = {
                transparent: [],
                gray: ['ring-gray'],
                primary: ['ring-primary'],
                success: ['ring-success'],
                danger: ['ring-danger'],
                warning: ['ring-warning'],
                info: ['ring-info'],
                help: ['ring-help'],
            };

            if (this.cva.disabled$()) {
                tailwindClasses.push('bg-surface-background-dark', 'border-surface-background');
            } else {
                tailwindClasses.push('border-surface-background-darker', 'hover:border-surface-background-darken');
            }

            if (this.type() === 'file') {
                tailwindClasses.push('bg-transparent', 'text-sm');
            }

            tailwindClasses.push(...focusRing[color]);

            tailwindClasses.push(rounded ? 'rounded-full' : 'rounded');

            return tailwindClasses.join(' ');
        })
    );

    input(event: Event) {
        if (event.target) this.cva.value = (event.target as HTMLInputElement).value;
    }
}
